<section class="proj-beabloo-website box-portfolio-project">
  <article class="box-title-proj">
      <h1>Beabloo website<span class="paragraph-md"> 2019</span></h1>

    </article>
   <!-- start about company -->
   <a id="company"></a>
    <article class=" background-portfolio-beabloo-website">
      <div class="container">
        <h3>{{'company' | translate }}</h3>
        <p class="max-text-sm"><strong>{{'beabloo_info1' | translate }}</strong></p>
        <p class="max-text-sm">{{'beabloo_proposal' | translate }}</p>
        <a class="box-block primary-color paragraph-lg hide-lg" href="https://www.beabloo.com">www.beabloo.com</a>
      </div>
    </article>
    <img class="hide-sm-up" src="assets/images/portfolio/beabloo-website/website_image1_sm.jpg" alt="">
    <!-- end about company -->
    <article class="cf">
      <div class="container">
        <div class="box-2">
          <h3>{{'my_role' | translate }}</h3>
          <p>{{'beabloo_role' | translate }}</p>
        </div>
        <div class="box-2">
          <h3>{{'planing_scope' | translate }}</h3>
          <p>{{'beabloo_planing' | translate }}</p>
        </div>
      </div>
    </article>
    <article class="cf">
      <div class="container">
        <div class="cf box-2">
          <h3>{{'my_challenge' | translate }}</h3>
          <p>{{'beabloo_challenge' | translate }}</p>
        </div>
        <div class="cf box-2">
          <h3>{{'results' | translate }}</h3>
          <p><strong>{{'beabloo_results' | translate }}</strong>{{'beabloo_results2' | translate }}</p>
        </div>
      </div>
    </article>
    <article>
      <div class="container">
        <div class="cf">
          <h3 class="max-text">{{'analyze_website' | translate }}</h3>
          <p class="max-text">{{'analyze_beabloo' | translate }}</p>
          <img class="margin-sm-t" src="assets/images/portfolio/beabloo-website/beabloo-workshop_1.png" alt="workshop">
          <h6 class="max-text margin-md-t">{{'results_show' | translate }}</h6>
          <p class="max-text"><span class="point-text">1. </span>{{'beabloo_result1' | translate }}</p>
          <p class="max-text"><span class="point-text">2. </span>{{'beabloo_result2' | translate }}</p>
          <p class="max-text"><span class="point-text">3. </span>{{'beabloo_result3' | translate }}</p>
          <p class="max-text"><span class="point-text">4. </span>{{'beabloo_result4' | translate }}</p>
        </div>
      </div>
    </article>
  <!-- start about company -->
    <article>
      <div class="container">
        <div class="cf">
          <h3>{{'low_fidelity_mockup' | translate }}</h3>
          <p>{{'beabloo-low-fidelity-mockup' | translate }}</p>
          <img src="assets/images/portfolio/beabloo-website/low_fidelity_mockup.png" alt="low fidelity mockup">
        </div>
      </div>
    </article>


    <article>
      <div class="container">
        <div class="">
          <h3>{{'user_test_iteration' | translate }}</h3>
          <p>{{'beabloo_user_test' | translate }}
          <img class="margin-sm-t" src="assets/images/portfolio/beabloo-website/images_mockup.png" alt="mockups of the project">
          <p>{{'see_website' | translate }} <strong><a class="primary-color" href="https://www.beabloo.com">www.beabloo.com</a></strong></p>
        </div>
      </div>
    </article>

    <!-- start other projects -->
      <div class="container container-links cf margin-md-b">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: SmartCommerce
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    <!-- end other projects -->
    </section>
