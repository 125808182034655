<section class="proj-procter">
  <article class="box-title-proj">
      <h1>Procter&Gamble<span class="paragraph-md"> 2016</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
        <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
      </ul>
    </article>




   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
        <h3>{{'company' | translate }}</h3>
        <p><strong>{{'info_procter1' | translate }}</strong></p>
        <p>{{'info_procter2' | translate }}</p>
      </div>
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'mockups' | translate }}</h3>
        <img src="assets/images/portfolio/procter/procter_1.jpg" alt="diseño de la web de procter">
        <span class="footer-img">{{'img_procter1' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->


   <!-- start about purpose -->
   <a id="proposal"></a>
    <article>
      <div class="container-sm">
        <h3>{{'purpose' | translate }}</h3>
        <p><strong>{{'purpose_procter1' | translate }}</strong></p>
        <p>{{'purpose_procter2' | translate }}</p>
        <p>{{'purpose_procter3' | translate }}</p>
        <p>{{'purpose_procter4' | translate }}</p>
        <p class="margin-t">{{'purpose_procter5' | translate }}</p>
        <img src="assets/images/portfolio/procter/procter_2.jpg" alt="diseño de la web de procter">
        <span class="footer-img">{{'img_procter2' | translate }}</span>
         <p class="margin-md-t">{{'purpose_procter6' | translate }}</p>
      </div>
    </article>
    <!-- end about purpose -->


    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/tedclothing?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: tedclothing
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>

