<section class="proj-tedclothing">
  <article class="box-title-proj">
      <h1>Tedclothing<span class="paragraph-md"> 2015</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
        <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
      </ul>
    </article>




   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
        <h3>{{'company' | translate }}</h3>
        <p>{{'tedclothing_info1' | translate }}</p>
        <p>{{'tedclothing_info2' | translate }}</p>
      </div>
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'mockups' | translate }}</h3>
        <img src="assets/images/portfolio/tedclothing/tedclothing_1.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing1' | translate }}</span>
        <img src="assets/images/portfolio/tedclothing/tedclothing_2.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing2' | translate }}</span>
        <img src="assets/images/portfolio/tedclothing/tedclothing_3.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing3' | translate }}</span>
        <img src="assets/images/portfolio/tedclothing/tedclothing_4.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing4' | translate }}</span>
        <img src="assets/images/portfolio/tedclothing/tedclothing_5.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing5' | translate }}</span>
        <img src="assets/images/portfolio/tedclothing/tedclothing_6.jpg" alt="imagen de la web de tedclothing">
        <span class="footer-img">{{'img_tedclothing6' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->


   <!-- start about purpose -->
   <a id="proposal"></a>
    <article>
      <div class="container-sm">
        <h3>{{'purpose' | translate }}</h3>
        <p><strong>{{'purpose_tedclothing' | translate }}</strong></p>
        <p><span class="point-text">1. </span>{{'tedclothing_step1' | translate }}</p>
        <p><span class="point-text">2. </span>{{'tedclothing_step2' | translate }}</p>
        <p><span class="point-text">3. </span>{{'tedclothing_step3' | translate }}</p>
        <p><span class="point-text">4. </span>{{'tedclothing_step4' | translate }}</p>
        <p><span class="point-text">5. </span>{{'tedclothing_step5' | translate }}</p>
      </div>
    </article>
    <!-- end about purpose -->


    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/saltinbanquin?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: saltinbanquin
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>
