<section class="proj-backend">
  <article class="box-title-proj">
  <h1>Smartcommerce CMS<span class="paragraph-md"> 2016</span></h1>
  <ul>
    <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
    <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
    <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
  </ul>
</article>

<!-- start about company -->
<a id="company"></a>
<article>
  <div class="container-sm">
    <h3>{{'company' | translate}}</h3>
    <p><strong>{{'backend_text1' | translate}}</strong></p>
    <!-- <p>{{'backend_text2' | translate}}</p> -->
      <!-- <h4>Mi rol</h4>
      <p>Era responsable de la experiència completa en el CMS interno liderando el diseño visual y la usabilidad del producto. Trabajé conjuntamente con el CEO y el CTO de la empresa donde estuvieron involucrados 8 programadores.</p>
    <h4>Propuesta</h4>
    <p>El CMS se había diseñado hacía más de 4 años por trozos y sin ningún objetivo claro. Las funcionalidades del CMS habían crecido mucho y nos encontrábamos con un CMS desfasado para el mercado: poco intuitivo y con un largo proceso de aprendizaje. Se acordó abordar este proyecto por releases e ir mejorando las distintas funcionalidades en cada interacción.</p>
    <p><strong>Los objetivos que nos marcamos antes de empezar fueron:</strong></p>
    <ul>
      <li>Entregar valor en un periodo máximo de 2 meses</li>
      <li>Nueva propuesta visual</li>
      <li>Detectar las secciones más problemáticas y empezar a mejorar esas secciones</li>
      <li>Reducir los tiempos de las tareas de mantenimiento del e-commerce.</li>
      <li>Añadir métricas sobre conversión y rendimiento del e-commerce</li>
      <li>Diseñar un CMS adaptable a los diferentes dispositivos, priorizando el PC</li>
    </ul> -->
  </div>
</article>
<!-- end about company -->

<!-- start about image -->
<a id="mockup"></a>
<article>
  <div class="container container-img">
    <h3>{{'mockups' | translate}}</h3>
    <img src="assets/images/portfolio/backend/backend_1.jpg" alt="diseño de la web de backend">
    <span class="footer-img">{{'analyze_numbers' | translate}}</span>
    <img src="assets/images/portfolio/backend/backend_2.jpg" alt="diseño de la web de backend">
    <span class="footer-img">{{'create_campaign' | translate}}</span>
    <img src="assets/images/portfolio/backend/backend_3.jpg" alt="diseño de la web de backend">
    <span class="footer-img">{{'campaigns_created' | translate}}</span>
  </div>
</article>
<!-- end about image -->


<!-- start about purpose -->
<a id="proposal"></a>
<article>
  <div class="container-sm">
    <h3>{{'purpose' | translate}}</h3>
    <p>{{'purpose_backend' | translate}}</p>
    <p><strong>{{'backend_steps' | translate}}</strong></p>
    <p><span class="point-text">1. </span>{{'backend_step1' | translate}}</p>
    <p><span class="point-text">2. </span>{{'backend_step2' | translate}}</p>
    <p><span class="point-text">3. </span>{{'backend_step3' | translate}}</p>
    <p><span class="point-text">4. </span>{{'backend_step4' | translate}}</p>
  </div>
</article>
<!-- end about purpose -->


<!-- start other projects -->
<article>
  <div class="container-sm container-links cf">
      <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
        <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
        {{'return_portfolio' | translate}}
      </a>
      <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/xerpa?lang=' + this.translate.currentLang" translate>
        {{'view_project' | translate}}: CityXerpa
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </a>
  </div>
</article>
<!-- end other projects -->
</section>
