<!-- introducción -->
<article id="home" class="box-presentation">
	<div class="container-home">
		<h2 translate>welcomeMsg</h2>
		<h1 translate>subWelcome</h1>
    <img class="img-home" src="assets/images/perfil/bg_home_sm.jpg" alt="">

	</div>

</article>

<blockquote class="sentence-famous">
	<div class="container">
		<h4>"A User interface is like a joke. If you have to explain it, it's not that good." <span>Martin LeBlanc</span></h4>
	</div>
</blockquote>
<!-- end introducción -->

<!-- welcome -->
<!--
<a name="view-about-me"></a>
<article>
  <div class="container">
    <h3 translate>welcomePortfolio</h3>
    <p class="text-center paragraph-md"><strong>{{'love_ux' | translate}}</strong> {{'welcomeMe' | translate}}</p>
    <p class="text-center primary-color paragraph-lg">"{{'gurusTool' | translate}}</p>
  </div>
</article> -->
<!-- ens welcome -->


<!--servicios -->
<article id="services" class="box-services">
  <div class="container">
    <h3 translate>services</h3>
    <div class="row">

      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/usability-design.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>usabilityDesign</h5>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_usability_1</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_usability_2</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_usability_3</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_usability_4</p>
        </div>
      </div>
      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/user-design.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>userExperience</h5>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_userexperience_1</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_userexperience_2</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_userexperience_3</p>
        </div>
      </div>
      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/web-design.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>service_designvisual_1</h5>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_designvisual_2</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_designvisual_3</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_designvisual_4</p>
        </div>
      </div>
      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/analytics.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>service_analytics_1</h5>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_analytics_2</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_analytics_3</p>
        </div>
      </div>

      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/thinking-design.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>designThinking</h5>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_designthinking_1</p>
          <p translate><i class="fa fa-check" aria-hidden="true"></i>service_designthinking_2</p>
        </div>
      </div>
      <div class="box-3">
        <div class="box-img-service">
          <img src="assets/images/icons/more-design.svg" alt="">
        </div>
        <div class="box-text-service">
          <h5 translate>whatMore</h5>
          <p translate>infoWhatMore</p>
        </div>
      </div>
    </div>
  </div>
</article>
<!-- end servicios -->

<!--portfolio -->

  <article id="works" class="box-portfolio">
    <div class="container">
      <h3 translate>{{'proyectos' | translate}}</h3>
      <div class="row hidden-sm-down">
        <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/beabloo-website?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/beabloo-website/beabloo-website_portada.jpg" alt="">
         <div class="box-text-portfolio proj-beabloo-website">
           <h4 class="line-subtitle">Beabloo website</h4>
           <p class="paragraph-sm" translate>see_summary_web_beabloo</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/backend/backend_portada.jpg" alt="">
         <div class="box-text-portfolio proj-backend">
           <h4 class="line-subtitle">Smartcommerce</h4>
           <p class="paragraph-sm" translate>infoSmartcommerce</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/xerpa?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/xerpa/xerpa_portada.jpg" alt="">
         <div class="box-text-portfolio proj-xerpa">
           <h4 class="line-subtitle">CityXerpa</h4>
           <p class="paragraph-sm" translate>infoXerpa</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/smartlink?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/smartlink/smartlink_portada.jpg" alt="">
         <div class="box-text-portfolio proj-smartlink">
           <h4 class="line-subtitle">Smartlink</h4>
           <p class="paragraph-sm" translate>infoSmartlink</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
      <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/beabloo-halo?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/beabloo-halo/halo_portada.jpg" alt="">
         <div class="box-text-portfolio proj-beabloo-halo">
           <h4 class="line-subtitle">{{'virtual_assistant' | translate}}</h4>
           <p class="paragraph-sm" translate>infoVirtualAssistant</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/makeyoursuit/makeyoursuit_portada.jpg" alt="">
         <div class="box-text-portfolio proj-makeyoursuit">
           <h4 class="line-subtitle">Makeyoursuit</h4>
           <p class="paragraph-sm" translate>infoMakeyoursuit</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>


  </div>

      <div id="carousel-example-generic" class="carousel slide hidden-md-up" data-ride="carousel">

        <!-- Wrapper for slides -->
        <div class="carousel-inner" role="listbox">
           <div class="carousel-item">
            <a [attr.href]="'#/portfolio/beabloo-website?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/beabloo-website/beabloo-website_portada.jpg" alt="imagen del proyecto"></a>
          </div>
          <div class="carousel-item">
            <a [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/backend/backend_portada.jpg" alt="imagen del proyecto"></a>
          </div>
          <div class="carousel-item active">
            <a [attr.href]="'#/portfolio/xerpa?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/xerpa/xerpa_portada.jpg" alt="imagen del proyecto"></a>
          </div>
          <div class="carousel-item">
            <a [attr.href]="'#/portfolio/beabloo-halo?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/beabloo-halo/halo_portada.jpg" alt="imagen del proyecto"></a>
          </div>
          <div class="carousel-item">
            <a [attr.href]="'#/portfolio/smartlink?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/smartlink/smartlink_portada.jpg" alt="imagen del proyecto"></a>
          </div>
          <div class="carousel-item">
            <a [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/makeyoursuit/makeyoursuit_portada.jpg" alt="imagen del proyecto"></a>
          </div>
        </div>

        <!-- Controls -->
        <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
          <span class="icon-prev" aria-hidden="true"></span>
          <span class="sr-only" translate>Previous</span>
        </a>
        <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
          <span class="icon-next" aria-hidden="true"></span>
          <span class="sr-only" translate>Next</span>
        </a>
      </div>
        <a class="btn btn-primary btn-center" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>viewAllProjects</a>
    </div>
  </article>

<!-- end portfolio -->

<!--Currículim -->
<article id="curriculum" class="box-curriculum cf">
  <div class="">
    <h3 translate>curriculum</h3>
    <!-- Nav tabs -->
    <div class="box-ul-curriculum">
      <ul class="nav nav-pills ul-curriculum" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#knowledge" role="tab" translate>knowledge</a>
        </li>
        <li class="nav-item">
          <a id="tab_about" class="nav-link" data-toggle="tab" href="#aboutme" role="tab" translate>aboutMe</a>
        </li>
        <li class="nav-item">
          <a id="tab_experience" class="nav-link" data-toggle="tab" href="#experience" role="tab" translate>experience</a>
        </li>
        <li class="nav-item">
          <a id="tab_education" class="nav-link" data-toggle="tab" href="#education" role="tab" translate>studies</a>
        </li>
        <li class="nav-item">
          <a id="tab_howwork" class="nav-link" data-toggle="tab" href="#howwork" role="tab" translate>processWork</a>
        </li>
      </ul>

    </div>

    <!-- Tab panes -->
    <div class="tab-content container">
      <!-- tab conocimientos inicio -->
      <div class="tab-pane active" id="knowledge" role="tabpanel">
        <div class="box-left">
          <p class="bold" translate>ImAriadna</p>
          <p translate>{{'ImAriadna2' | translate}}</p>
          <p translate>ImAriadna3</p>
          <p translate>technologicAboutMe</p>
          <a class="btn btn-primary-outline margin-md-t" (click)="tabsNav('tab_about')" onclick="$('#tab_about').tab('show');" translate>knowMoreAboutMe</a>
        </div>
        <div class="box-right">
          <div class="cf margin-sm-b">
            <div class="text-programs"><span>Sketch, Axure, Adobe</span></div>
            <div class="box-progress">
              <div class="box-progress-quantity" style="width:95%"></div>
            </div>
          </div>

          <div class="cf margin-sm-b">
            <div class="text-programs"><span>Zeplin, Invision</span></div>
            <div class="box-progress">
              <div class="box-progress-quantity" style="width:80%"></div>
            </div>
          </div>

          <div class="cf margin-sm-b">
            <div class="text-programs"><span>Jira, Trello, Confluence</span></div>
            <div class="box-progress">
              <div class="box-progress-quantity" style="width:75%"></div>
            </div>
          </div>

          <div class="cf margin-sm-b">
            <div class="text-programs"><span>Google Analytics, Hotjar</span></div>
            <div class="box-progress">
              <div class="box-progress-quantity" style="width:70%"></div>
            </div>
          </div>

          <div class="cf margin-sm-b">
            <div class="text-programs"><span>HTML5, CSS3, SASS</span></div>
            <div class="box-progress">
              <div class="box-progress-quantity" style="width:60%"></div>
            </div>
          </div>

        </div>
      </div>
      <!-- tab conocimientos fin -->
      <!-- tab conocimientos about me -->
      <div class="tab-pane" id="aboutme" role="tabpanel">
        <div class="box-left">
          <p translate>webInspirations</p>
          <p translate>ABPassion</p>
          <p translate>ABPassion2</p>
          <a class="btn btn-primary-outline margin-md-t" (click)="tabsNav('tab_experience')" onclick="$('#tab_experience').tab('show');" translate>readWork</a>
        </div>
        <div class="box-right">
          <img src="assets/images/perfil/perfil_ari.jpg" alt="">
        </div>
      </div>
      <div class="tab-pane" id="experience" role="tabpanel">
        <div class="row">
           <div class="box-left">
            <h5 class="text-subtitle" translate>beabloo_ux_design</h5>
            <h6><strong>Beabloo. </strong><span translate>date_beabloo</span></h6>
            <ul class="list-functions">
              <li class="li-title" translate>functions</li>
              <li><span translate>beabloo_function1</span></li>
              <li><span translate>beabloo_function2</span></li>
              <li><span translate>beabloo_function3</span></li>
              <li><span translate>beabloo_function4</span></li>
            </ul>
          </div>
          <div class="box-right">
            <h5 class="text-subtitle" translate>designFrontend</h5>
            <h6><strong>SmartCommerce. </strong><span translate>dateSmartCommerce</span></h6>
            <ul class="list-functions">
              <li class="li-title" translate>functions</li>
              <li><span translate>conceptDesign</span></li>
              <li><span translate>responsiveDesign</span></li>
              <li><span translate>wireframePrototype</span></li>
              <li><span translate>frontEndDeveloper</span></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="box-left">
            <h5 class="text-subtitle" translate>design_web_grafic</h5>
            <h6><strong>Makeyoursuit. </strong><span translate>date_makeyoursuit</span></h6>
            <ul class="list-functions">
              <li class="li-title" translate>functions</li>
              <li><span class="text-functions" translate>web_redesign</span></li>
              <li><span class="text-functions" translate>adapt_responsive</span></li>
              <li><span class="text-functions" translate>design_banner</span></li>
              <li><span class="text-functions" translate>photo_change</span></li>
            </ul>
          </div>
          <div class="box-right">
            <h5 class="text-subtitle" translate>intern_graphic_design</h5>
            <h6><strong>Offerum. </strong><span translate>date_offerum</span></h6>
            <ul class="list-functions">
              <li class="li-title" translate>functions</li>
              <li><span translate>use_img</span></li>
              <li><span translate>photo_change</span></li>
              <li><span translate>prepare_img_web</span></li>
              <li><span translate>design_target</span></li>
            </ul>
          </div>
        </div>
        <div class="separation-top margin-md-b">
          <div class="center-table">
            <span translate>see_my_education</span>
            <a class="btn btn-primary-outline" (click)="tabsNav('tab_education')" onclick="$('#tab_education').tab('show');" translate>see_how_education</a>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="education" role="tabpanel">
        <div class="row">
          <div class="box-left">
            <h5 class="text-subtitle" translate>course_service_design</h5>
            <h6><strong translate>UxerSchool</strong><span> 2019 - 2019</span></h6>
            <p class="hidden-xs-down" translate>master_service</p>
            <h5 class="margin-md-t text-subtitle" translate>master_design_web</h5>
            <h6><strong translate>bau</strong><span> 2014 - 2015</span></h6>
            <p class="hidden-xs-down" translate>master_bau</p>
            <h5 class="margin-md-t text-subtitle" translate>design_degree</h5>
            <h6><strong translate>barcelona_university</strong><span> 2010 - 2014</span></h6>
            <p class="hidden-xs-down" translate>info_degree</p>
            <!-- <h5 class="margin-md-t text-subtitle" translate>Cursos</h5>
            <h6><strong>Diferentes temáticas relacionadas con el sector del diseño web</strong></h6>
            <p class="hidden-xs-down" translate><b>2016</b> Curso Técnico de Javascript y Jquery (Seeway, Barcelona)</p>
            <p class="hidden-xs-down" translate><b>2014</b> Curso Google Analítics. (Mide y vencerás, Barcelona)</p>
            <p class="hidden-xs-down" translate><b>2014</b> Curso Básico de Marketing Digital de Google e IAB Spain</p>
            <p class="hidden-xs-down" translate><b>2013</b> Curso reducido de Posicionamiento Web (Barcelona Activa)</p>
            <p class="hidden-xs-down" translate><b>2012</b> Curso de iniciación en Arteterapia (Universidad de verano de la UAB)</p> -->
            <a class="btn btn-primary-outline margin-md-t" (click)="tabsNav('tab_howwork')" onclick="$('#tab_howwork').tab('show');" translate>see_process_work</a>
          </div>
          <div class="box-right">
             <img src="assets/images/perfil/perfil_ari.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="tab-pane" id="howwork" role="tabpanel">
        <div class="">
          <h4 class="margin-lg-b margin-md-t" translate>show_steps_work_agile</h4>
       <!--    <h4 class="title-description-sub" translate>show_steps_work</h4> -->
        </div>
        <div class="margin-lg-b cf">
          <div class="box-2">
            <p translate><i class="fa fa-check fa-primary-color" aria-hidden="true"></i>info_step_1</p>
            <p translate><i class="fa fa-check fa-primary-color" aria-hidden="true"></i>info_step_2</p>
            <p translate><i class="fa fa-check fa-primary-color" aria-hidden="true"></i>info_step_3</p>
          </div>
          <div class="box-2">
              <p translate><i class="fa fa-check fa-primary-color" aria-hidden="true"></i>info_step_4</p>
              <p translate><i class="fa fa-check fa-primary-color" aria-hidden="true"></i>info_step_5</p>
          </div>
        </div>
          <!-- <div class="box-2">
            <div class="box-img-process">
              <img src="assets/images/icons/search-process.svg" alt="">
            </div>
            <div class="box-text-process">
              <h5>1. {{'step_1' | translate}}</h5>
              <p translate>info_step_1</p>
            </div>
          </div>
          <div class="box-2">
            <div class="box-img-process">
              <img src="assets/images/icons/code-process.svg" alt="">
            </div>
            <div class="box-text-process">
              <h5>3. {{'step_3' | translate}}</h5>
              <p translate>info_step_3</p>
            </div>
          </div>
          <div class="box-2">
            <div class="box-img-process">
              <img src="assets/images/icons/design-process.svg" alt="">
            </div>
            <div class="box-text-process">
              <h5>2. {{'step_2' | translate}}</h5>
              <p translate>info_step_2</p>
            </div>
          </div>
          <div class="box-2">
            <div class="box-img-process">
              <img src="assets/images/icons/final-process.svg" alt="">
            </div>
            <div class="box-text-process">
              <h5>4. {{'step_4' | translate}}</h5>
              <p translate>info_step_4</p>
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <div class="buttons-cv">
    <div class="center-elements">
      <a *ngIf="selectedLang === 'es'" class="margin-sm-r" href="assets/images/pdf/ariadna_ercilla_cv_en.pdf" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
      <a *ngIf="selectedLang === 'en'" class="margin-sm-r" href="assets/images/pdf/ariadna_ercilla_cv_en.pdf" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
      <a *ngIf="selectedLang === 'cat'" class="margin-sm-r" href="assets/images/pdf/ariadna_ercilla_cv_cat.pdf" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
      <a href="https://www.linkedin.com/in/ariadnaercilla/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
    </div>
  </div>
</article>
<!-- end servicios -->

<!--contacto -->

<article id="contact" class="box-contact">
  <div class="container">
    <h3 translate>contact</h3>
    <p class="text-center no-margin" translate>send_email</p>
    <p class="text-center" translate>send_email2</p>
      <a class="text-center box-block" href="mailto:a.ercilla.m@gmail.com">a.ercilla.m@gmail.com</a>
  </div>
</article>

<!-- end contacto -->
