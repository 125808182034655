<section class="proj-makeyoursuit">
  <article class="box-title-proj">
  <h1>Makeyoursuit<span class="paragraph-md"> 2015</span></h1>
  <ul>
    <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
    <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
    <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
    <li><a (click)="scroll('tecnics')">{{'tecnics_used' | translate}}</a></li>

  </ul>
</article>




<!-- start about company -->
<a id="company"></a>
<article>
  <div class="container-sm">
    <h3>{{'company' | translate }}</h3>

    <p>{{'purpose_makeyoursuit1' | translate }}</p>
    <p>{{'purpose_makeyoursuit2' | translate }}</p>
  </div>
</article>
<!-- end about company -->

<!-- start about image -->
<a id="mockup"></a>
<article>
  <div class="container container-img">
    <h3>{{'mockups' | translate }}</h3>
    <img src="assets/images/portfolio/makeyoursuit/makeyoursuit_1.jpg" alt="web makeyoursuit">
    <span class="footer-img">{{'img_makeyoursuit1' | translate }}</span>
    <img src="assets/images/portfolio/makeyoursuit/makeyoursuit_4.jpg" alt="web makeyoursuit">
    <span class="footer-img">{{'img_makeyoursuit2' | translate }}</span>
    <img src="assets/images/portfolio/makeyoursuit/makeyoursuit_3.jpg" alt="web makeyoursuit">
    <span class="footer-img">{{'img_makeyoursuit3' | translate }}</span>
    <img src="assets/images/portfolio/makeyoursuit/makeyoursuit_2.jpg" alt="web makeyoursuit">
    <span class="footer-img">{{'img_makeyoursuit4' | translate }}</span>
  </div>
</article>
<!-- end about image -->


<!-- start about purpose -->
<a id="proposal"></a>
<article>
  <div class="container-sm">
    <h3>{{'purpose' | translate }}</h3>
    <p><strong>{{'makeyoursuit_steps' | translate }}</strong></p>
    <p><span class="point-text">1. </span>{{'makeyoursuit_step1' | translate }}</p>
    <p><span class="point-text">2. </span>{{'makeyoursuit_step2' | translate }}</p>
    <p><span class="point-text">3. </span>{{'makeyoursuit_step3' | translate }}</p>
  </div>
</article>
<!-- end about purpose -->


<!-- start about programs -->
<a id="tecnics"></a>
<article>
  <div class="container-sm">
    <h3>{{'tecnics_used' | translate }}</h3>
    <p><span class="point-text">{{'title_tecnic_makeyoursuit1' | translate }}</span>{{'tecnic_makeyoursuit1' | translate }}</p>
    <p><span class="point-text">{{'title_tecnic_makeyoursuit2' | translate }}</span>{{'tecnic_makeyoursuit2' | translate }}</p>
  </div>

</article>
<!-- end about programs -->

<!-- start other projects -->
<article>
  <div class="container-sm container-links cf">
    <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
      <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
      {{'return_portfolio' | translate }}
    </a>
    <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/schweppes?lang=' + this.translate.currentLang" translate>
      {{'view_project' | translate }}: Schweppes
      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
    </a>
  </div>
</article>
    <!-- end other projects -->
    </section>
