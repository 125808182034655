<header class="cf" (scroll)="onScroll($event)">
  <div class="container-lg">
    <a class="logo-menu" href="#" (click)="onClickMenu('home')" translate>name</a>
    <nav class="navbar navbar-light bg-faded">
      <button class="navbar-toggler hidden-lg-up" type="button" data-toggle="collapse" data-target="#navbar-header" aria-controls="navbar-header" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-navicon"></i>
      </button>
      <div class="collapse navbar-toggleable-md" id="{{id}}">
        <ul id="navbar-scroll" class="nav navbar-nav affix-top" data-spy="affix">
          <li class="nav-item">
            <a [ngClass]="{'active': currentSection == 'home'}" class="nav-link" href="/#/index" (click)="onClickMenu('home')"><i class="hidden-md-up fa fa-home" aria-hidden="true"></i>{{'home' | translate}} <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a [ngClass]="{'active': currentSection == 'services'}" class="nav-link" href="/#/index#services" (click)="onClickMenu('services')"><i class="hidden-md-up fa fa-briefcase" aria-hidden="true"></i>{{'services' | translate}}<i class="hidden-md-up fa fa-address-card-o" aria-hidden="true"></i></a>
          </li>
          <li class="nav-item">
            <a [ngClass]="{'active': currentSection == 'works'}" class="nav-link" href="/#/index#works" (click)="onClickMenu('works')"><i class="hidden-md-up fa fa-picture-o" aria-hidden="true"></i>{{'proyectos' | translate}}</a>
          </li>
           <li class="nav-item">
            <a [ngClass]="{'active': currentSection == 'curriculum'}" class="nav-link" href="/#/index#curriculum" (click)="onClickMenu('curriculum')"><i class="hidden-md-up fa fa-briefcase" aria-hidden="true"></i>{{'curriculum' | translate}}<i class="hidden-md-up fa fa-address-card-o" aria-hidden="true"></i></a>
          </li>
          <li class="nav-item">
            <a [ngClass]="{'active': currentSection == 'contact'}" class="nav-link" href="/#/index#contact" (click)="onClickMenu('contact')"><i class="hidden-md-up fa fa-envelope" aria-hidden="true"></i>{{'contact' | translate}}</a>
          </li>
          <li class="nav-item nav-languages">

            <a [ngClass]="{'language-selected': currentLanguage == 'es'}" class="nav-link" (click)="changeLang('es')">es</a>
            <a [ngClass]="{'language-selected': currentLanguage == 'en'}" class="nav-link" (click)="changeLang('en')">en</a>
<!--             <a [ngClass]="{'language-selected': currentLanguage == 'cat'}" class="nav-link" (click)="changeLang('cat')">
              <i class="hidden-md-up fa fa-envelope" aria-hidden="true"></i>
              cat
            </a> -->

          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
