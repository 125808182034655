<section class="proj-schweppes">
  <article class="box-title-proj">
      <h1>Schweppes<span class="paragraph-md"> 2017</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
        <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
      </ul>
    </article>


   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
       <h3>{{'company' | translate }}</h3>
        <p><strong>{{'schweppes_info1' | translate }}</strong></p>
        <p>{{'schweppes_info2' | translate }}</p>
      </div>
        
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'mockups' | translate }}</h3>
        <img src="assets/images/portfolio/schweppes/schweppes_1.jpg" alt="diseño de la web de schweppes">
        <span class="footer-img">{{'img_schweppes1' | translate }}</span>
        <img src="assets/images/portfolio/schweppes/schweppes_2.jpg" alt="diseño de la web de schweppes">
        <span class="footer-img">{{'img_schweppes2' | translate }}</span>
        <img src="assets/images/portfolio/schweppes/schweppes_3.jpg" alt="diseño de la web de schweppes">
        <span class="footer-img">{{'img_schweppes3' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->

    <!-- start about purpose -->
   <a id="proposal"></a>
    <article>
      <div class="container-sm">
        <h3>{{'purpose' | translate }}</h3>
        <p><strong>{{'purpose_schweppes1' | translate }}</strong></p>
        <p>{{'purpose_schweppes2' | translate }}</p>
        </div>
    </article>
    <!-- end about purpose -->


    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/procter?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: Procter&Gamble
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>
