<section class="proj-saltinbanquin">
  <article class="box-title-proj">
  <h1>Saltinbanquin<span class="paragraph-md"> 2014</span></h1>
  <ul>
    <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
    <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
    <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
  </ul>
</article>




<!-- start about company -->
<a id="company"></a>
<article>
  <div class="container-sm">
    <h3>{{'company' | translate }}</h3>
    <p>{{'saltin_banquin_info1' | translate }}</p>
    <p>{{'saltin_banquin_info2' | translate }}</p>
    <p class="paragraph-lg">{{'saltin_banquin_info3' | translate }}<a class="primary-color" href="https://www.saltinbanquin.com/" target="_blank">https://www.saltinbanquin.com/</a></p>
  </div>
</article>
<!-- end about company -->

<!-- start about image -->
<a id="mockup"></a>
<article>
  <div class="container container-img">
    <h3>{{'mockups' | translate }}</h3>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_1.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin1' | translate }}</span>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_2.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin2' | translate }}</span>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_3.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin3' | translate }}</span>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_4.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin4' | translate }}</span>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_5.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin5' | translate }}</span>
    <img src="assets/images/portfolio/saltinbanquin/saltinbanquin_6.jpg" alt="diseño de la web de saltinbanquin">
    <span class="footer-img">{{'img_saltinbanquin6' | translate }}</span>
  </div>
</article>
<!-- end about image -->


<!-- start about purpose -->
<a id="proposal"></a>
<article>
  <div class="container-sm">
    <h3>{{'purpose' | translate }}</h3>
    <p><strong>{{'purpose_saltinbanquin' | translate }}</strong></p>
    <p><span class="point-text">1. </span>{{'saltinbanquin_step1' | translate }}</p>
    <p><span class="point-text">2. </span>{{'saltinbanquin_step2' | translate }}</p>
    <p><span class="point-text">3. </span>{{'saltinbanquin_step3' | translate }}</p>
  </div>
</article>
<!-- end about purpose -->

<!-- start other projects -->
<article>
  <div class="container-sm container-links cf">
    <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
      <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
      {{'return_portfolio' | translate }}
    </a>
    <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/nestle?lang=' + this.translate.currentLang" translate>
      {{'view_project' | translate }}: Nestle
      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
    </a>
  </div>
</article>
    <!-- end other projects -->
    </section>
