<section class="proj-nestle">
  <article class="box-title-proj">
      <h1>Nestle<span class="paragraph-md"> 2017</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
        <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
      </ul>
    </article>


   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
       <h3>{{'company' | translate }}</h3>
        <p><strong>{{'nestle_info1' | translate }}</strong></p>
        <a class="text-center box-block primary-color paragraph-lg" target="_blank" href="http://nestle.brandensite.com/">nestle.brandensite.com/</a>
      </div>
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'mockups' | translate }}</h3>
        <img src="assets/images/portfolio/nestle/nestle_1.jpg" alt="diseño de la web de nestle">
        <span class="footer-img">{{'img_nestle1' | translate }}</span>
        <img src="assets/images/portfolio/nestle/nestle_2.jpg" alt="diseño de la web de nestle">
        <span class="footer-img">{{'img_nestle2' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->

    <!-- start about purpose -->
   <a id="proposal"></a>
    <article>
      <div class="container-sm">
        <h3>{{'purpose' | translate }}</h3>
        <p><strong>{{'purpose_nestle1' | translate }}</strong></p>
        <p>{{'purpose_nestle2' | translate }}</p>
        <p>{{'purpose_nestle3' | translate }} Cocacola, <a target="_blank" href="http://www.compra-facil.es">Procter&Gamble</a>, <a target="_blank" href="http://adamfoods.brandensite.com/catalogue/cp/08027/retailer/elcorteingles">Adamfoods</a>, <a target="_blank" href="http://scholl.brandensite.com">Scholl</a>.</p>
        </div>
    </article>
    <!-- end about purpose -->


    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/beabloo-website?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: Beabloo website
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>
