<section class="proj-puntoyseguido">
  <section class="proj-puntoyseguido">
  <article class="box-title-proj">
  <h1>Puntoyseguido<span class="paragraph-md"> 2014</span></h1>
  <ul>
     <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
    <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
    <li><a (click)="scroll('proposal')">{{'purpose' | translate}}</a></li>
    <li><a (click)="scroll('tecnics')">{{'tecnics_used' | translate}}</a></li>
  </ul>
</article>




<!-- start about company -->
<a id="company"></a>
<article>
  <div class="container-sm">
    <h3>{{'company' | translate}}</h3>

    <p><strong>{{'info_puntoyseguido1' | translate}}</strong></p>
    <p>{{'info_puntoyseguido2' | translate}}</p>
  </div>
</article>
<!-- end about company -->

<!-- start about image -->
<a id="mockup"></a>
<article>
  <div class="container container-img">
    <h3>{{'mockups' | translate}}</h3>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_1.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido1' | translate}}</span>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_2.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido2' | translate}}</span>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_3.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido3' | translate}}</span>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_4.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido4' | translate}}</span>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_5.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido5' | translate}}</span>
    <img src="assets/images/portfolio/puntoyseguido/puntoyseguido_6.jpg" alt="diseño de la web de puntoyseguido">
    <span class="footer-img">{{'img_puntoyseguido6' | translate}}</span>
  </div>
</article>
<!-- end about image -->


<!-- start about purpose -->
<a id="proposal"></a>
<article>
  <div class="container-sm">
    <h3>{{'purpose' | translate}}</h3>
    <p><strong>{{'purpose_puntoyseguido1' | translate}}</strong></p>
    <p><strong>{{'purpose_puntoyseguido2' | translate}}</strong></p>
    <p><span class="point-text">1. </span>{{'puntoyseguido_step1' | translate}}</p>
    <p><span class="point-text">2. </span>{{'puntoyseguido_step2' | translate}}</p>
    <p><span class="point-text">3. </span>{{'puntoyseguido_step3' | translate}}</p>
    <p>{{'process_work' | translate}}<a href="http://irenearan.github.io/" target="#blank">{{'memory_project' | translate}}</a></p>
  </div>
</article>
<!-- end about purpose -->

<!-- start about programs -->
<a id="tecnics"></a>
<article>
  <div class="container-sm">
    <h3>{{'tecnics_used' | translate}}</h3>
    <p><span class="point-text">{{'title_tecnic_puntoyseguido1' | translate}}</span>{{'tecnic_puntoyseguido1' | translate}}</p>
    <p><span class="point-text">{{'title_tecnic_puntoyseguido2' | translate}}</span>{{'tecnic_puntoyseguido2' | translate}}</p>
    <p><span class="point-text">{{'title_tecnic_puntoyseguido3' | translate}}</span>{{'tecnic_puntoyseguido3' | translate}}
    </p>
  </div>
</article>
<!-- end about programs -->

<!-- start other projects -->
<article>
  <div class="container-sm container-links cf">
      <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
        <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
        {{'return_portfolio' | translate}}
      </a>
      <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/procter?lang=' + this.translate.currentLang" translate>
        {{'view_project' | translate}}: procter
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </a>
  </div>
</article>
<!-- end other projects -->
</section>
