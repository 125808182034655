<section class="proj-trenes">
  <article class="box-title-proj">
      <h1>{{'train' | translate }}<span class="paragraph-md"> 2000 - 2017</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
      </ul>
    </article>




   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
        <h3>{{'company' | translate }}</h3>
        <p><strong>{{'trenes_info1' | translate }}</strong>{{'trenes_info2' | translate }}</p>
      </div>
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'img' | translate }}</h3>
        <img src="assets/images/portfolio/trenes/trenes_1.jpg" alt="imágen maqueta tren">
        <span class="footer-img">{{'img_trenes1' | translate }}</span>
        <img src="assets/images/portfolio/trenes/trenes_2.jpg" alt="imágen maqueta tren">
        <span class="footer-img">{{'img_trenes2' | translate }}</span>
        <img src="assets/images/portfolio/trenes/trenes_3.jpg" alt="imágen maqueta tren">
        <span class="footer-img">{{'img_trenes3' | translate }}</span>
        <img src="assets/images/portfolio/trenes/trenes_4.jpg" alt="imágen maqueta tren">
        <span class="footer-img">{{'img_trenes4' | translate }}</span>
        <img src="assets/images/portfolio/trenes/trenes_5.jpg" alt="imágen maqueta tren">
        <span class="footer-img">{{'img_trenes5' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->


    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: smartcommerce
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>
