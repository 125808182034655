    <section class="proj-boxeador">
      <article class="box-title-proj">
      <h1>J-SUGE<span class="paragraph-md"> 2013</span></h1>
      <ul>
        <li><a (click)="scroll('company')">{{'company'| translate}}</a></li>
        <li><a (click)="scroll('mockup')">{{'mockups' | translate}}</a></li>
      </ul>
    </article>


   <!-- start about company -->
   <a id="company"></a>
    <article>
      <div class="container-sm">
        <h3>{{'company' | translate }}</h3>

        <p><strong>{{'purpose_jsuge' | translate }}</strong></p>
        <p>{{'purpose_jsuge2' | translate }}</p>
      </div>
    </article>
    <!-- end about company -->

    <!-- start about image -->
    <a id="mockup"></a>
    <article>
      <div class="container container-img">
        <h3>{{'mockups' | translate }}</h3>
        <img src="assets/images/portfolio/boxeador/boxeador_1.jpg" alt="diseño de la web de boxeador">
        <span class="footer-img">{{'img_jsuge1' | translate }}</span>
        <img src="assets/images/portfolio/boxeador/boxeador_2.jpg" alt="diseño de la web de boxeador">
        <span class="footer-img">{{'img_jsuge2' | translate }}</span>
        <img src="assets/images/portfolio/boxeador/boxeador_3.jpg" alt="diseño de la web de boxeador">
        <span class="footer-img">{{'img_jsuge3' | translate }}</span>
        <img src="assets/images/portfolio/boxeador/boxeador_4.jpg" alt="diseño de la web de boxeador">
        <span class="footer-img">{{'img_jsuge4' | translate }}</span>
        <img src="assets/images/portfolio/boxeador/boxeador_5.jpg" alt="diseño de la web de boxeador">
        <span class="footer-img">{{'img_jsuge5' | translate }}</span>
      </div>
    </article>
    <!-- end about image -->

    <!-- start other projects -->
    <article>
      <div class="container-sm container-links cf">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/nestle?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: Nestle
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    </article>
    <!-- end other projects -->
    </section>
