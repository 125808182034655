<section class="proj-beabloo-cms box-portfolio-project">
  <article class="box-title-proj">
      <h1>Digital Signage CMS<span class="paragraph-md"> 2019</span></h1>
       <ul>
        <li><a (click)="scroll('company')">{{'project_info'| translate}}</a></li>
        <li><a (click)="scroll('user_research')">{{'user_research'| translate}}</a></li>
        <li><a (click)="scroll('prototype')">{{'prototype'| translate}}</a></li>
        <li><a (click)="scroll('design')">{{'design_user_test'| translate}}</a></li>
      </ul>
    </article>
   <!-- start about company -->
   <a id="company"></a>
    <article class="background-portfolio-beabloo-cms">
      <div class="container ">
        <h3>Digital Signage CMS</h3>
        <p class="max-text-sm"><strong>Herramienta sofisticada para que las empresas puedan gestionar el contenido que mostraran en su cartelería digital: diseñar creatividades, subir contenido, añadir condicionantes de tiempo, género o edad.</strong></p>
        <p class="max-text-sm">El CMS nació hace 6 años diseñado por los desarrolladores de la compañía y donde se habían ido añadiendo muchas funcionalidades diferentes.</p>
      </div>
    </article>
    <img class="hide-sm-up" src="assets/images/portfolio/beabloo-cms/background_cms_mobile.jpg" alt="">
    <!-- end about company -->
    <article class="bg-light cf">
      <div class="container cf">
        <div class="box-2">
          <h3>{{'my_role' | translate }}</h3>
          <p>Liderar el rediseño de esta plataforma para facilitar el uso de la herramienta, trabajando conjuntamente con el Product Manager, Desarrollo, Departamento de Contenido y Localización</p>    
        </div>
        <div class="box-2">
          <h3>Definición proyecto</h3>
          <p>Rediseñar el CMS era un proyecto de gran envergadura y lo queríamos enfocar con pequeños entregables que añadieran valor al usuario. Para poder abordar el proyecto satisfactoriamente se hizo un proceso largo de investigación para poder priorizar qué pantallas tenían que mejorarse.</p>
        </div>
      </div>
    </article>

  <!-- start about company -->
   <a id="user_research"></a>
    <article class="cf">
      <div class="container cf">
        <div class="cf">
          <h3>Fase 1: Investigación</h3>
          <p class="max-text">La fase de investigación fue la más extensa, fue un periodo de recopilar mucha información de diferentes fuentes para poder llegar a concluir qué apartados eran los más críticos y definir las primeras fases del rediseño.</p>
          <div class="margin-md-t cf">
            <h4 class="text-subtitle-color-brand">Benchmarking</h4>
            <p class="max-text">Se analizó diseño, funcionalidades y experiencia del usuario con diferentes herramientas del sector. Nos permitió detectar nuestros puntos fuertes en base a la competencia y listar diferentes funcionalidades o interacciones que eran interesantes.</p>
          </div>
        </div>
      </div>
      <div class="container-full">
        <img class="box-4" src="assets/images/portfolio/beabloo-cms/competitor1.jpg" alt="CMS competitor">
        <img class="box-4" src="assets/images/portfolio/beabloo-cms/competitor2.jpg" alt="CMS competitor">
        <img class="box-4" src="assets/images/portfolio/beabloo-cms/competitor3.jpg" alt="CMS competitor">
        <img class="box-4" src="assets/images/portfolio/beabloo-cms/competitor4.jpg" alt="CMS competitor">
        <p class="container footer-img-left">CMS de competidores</p>
      </div>
         
      <div class="container cf">
          <div class="margin-md-t cf">
            <h4 class="text-subtitle-color-brand">Workshop para analizar CMS actual</h4>
            <p class="max-text">Facilité un workshop donde participó profesionales del departamento de IT, Sales, Customer Service y Contenido. Se crearon 3 equipos y cada uno analizó un apartado del CMS listando aquellos elementos valiosos de la proposta actual (para mantenerlos en la nueva propuesta), los elementos confusos y aquellas funcionalidades que nunca habían utilizado ni sabían que hacían.</p>
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/analyze_old_web3.jpg" alt="workshop">
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/analyze_old_web.jpg" alt="workshop ">
            
          </div>

          <div class="margin-md-t cf">
            <h4 class="text-subtitle-color-brand">Analyze metrics</h4>
            <p class="max-text">Para entender el comportamiento de los usuarios, analizé con Google Analytics qué secciones visitaban más, en cuales estaban más tiempo, el rebote de abandono, etc. Las hipótesis obtenidas las cruzé con la herramienta Hotjar, que permite grabar sesiones de usuarios y entender cómo interactuan los usuarios reales con el CMS y en qué apartados se confuden.</p>
          </div>

          <p class="max-text">Cruzando todos los datos obtenidos se decidió que se abordaría la fase inicial del proyecto en 3 subfases:
          <p class="max-text"><span class="point-text">1. </span>Rediseño de la interfaz para actualizar únicamente la plataforma a nivel visual</p>
          <p class="max-text"><span class="point-text">2. </span>Mejorar elementos sencillos de toda la platafroma pero que facilitaran la interacción al usuario: Textos y mensajes de error confusos, selectores con las opciones no ordenadas alfabéticamente, añadir filtros para agilizar búsquedas, etc.</p>
          <p class="max-text"><span class="point-text">3. </span>Rediseñar el apartado de templates. Se detectó que era el apartado donde los usuarios dedicaban más tiempo porque no era intuitivo, no permitía ver en tiempo real los cambios de una template.</p>
      </div>
    </article>
  <!-- end -->

 <a id="prototype"></a>
    <article>
      <div class="container cf">
          <h3>Low Mockups - Prototype</h3>
          <p class="">En base a la información recopilada durante la fase de investigación se diseñaron las diferentes pantallas de la sección templates: desde crear o editar una template o adjudicar una template a una pantalla.</p>
          
          <div>
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/template1.png" alt="Template">
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/template_old1.jpg" alt="Template">
          </div>
          <div>
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/template2.png" alt="Template">
            <img class="box-2" src="assets/images/portfolio/beabloo-cms/template_old2.jpg" alt="Template">
          </div>
          <p clas="footer-img">{{'va_user_flow'| translate}}</p>
          
          <h4>El rediseño incorporaba estas mejoras</h4>
          <div class="box-2">
            <p><span class="point-text">1. </span>El usuario ve en tiempo real la edición de su template</p>
            <p><span class="point-text">2. </span>Se incorpora la función "Drag&Drop"</p>
            <p><span class="point-text">3. </span>Disminuyen las opciones que se tienen que modificar por código (html/css) y pasan a ser editables en el editor visual</p>
          </div>
          <div class="box-2">
            <p><span class="point-text">4. </span>Se incluyen pasos explicativos, sobre el proceso de creación de una template</p>
            <p><span class="point-text">5. </span>El listado de templates se rediseña, añadiendo un preview de las templates que facilita la elección.</p>
            <p><span class="point-text">6. </span>Simplificación de procesos y eliminación de características en desuso.</p>
          </div>

      </div>
  <a id="design"></a>
      <div class="container margin-md-t cf">
        <h3>{{'va_design'| translate}}</h3>
        <p class="">{{'va_design_text'| translate}}</p>
        <p><span class="point-text">1. </span>{{'va_design1'| translate}}</p>
        <p><span class="point-text">2. </span>{{'va_design2'| translate}}</p>
        <p><span class="point-text">3. </span>{{'va_design3'| translate}}</p>
        <img class="img-md" src="assets/images/portfolio/beabloo-cms/virtual_assistant_test.png" alt="User Test virtual assistant">
        <p clas="footer-img">User Test</p>
      </div>
    </article>

    <!-- start other projects -->
      <div class="container container-links cf margin-md-b">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: Makeyoursuit
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    <!-- end other projects -->
    </section>

