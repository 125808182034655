
<!--portfolio -->
<article id="works" class="box-portfolio">
 <div class="container">
   <h3 class="margin-lg-t" translate>{{'proyectos' | translate}}</h3>
    <div class="row hidden-sm-down">
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/beabloo-website?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/beabloo-website/beabloo-website_portada.jpg" alt="">
         <div class="box-text-portfolio proj-beabloo-website">
           <h4 class="line-subtitle">Beabloo website</h4>
           <p class="paragraph-sm" translate>see_summary_web_beabloo</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/backend/backend_portada.jpg" alt="">
         <div class="box-text-portfolio proj-backend">
           <h4 class="line-subtitle">Smartcommerce</h4>
           <p class="paragraph-sm" translate>infoSmartcommerce</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/xerpa?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/xerpa/xerpa_portada.jpg" alt="">
         <div class="box-text-portfolio proj-xerpa">
           <h4 class="line-subtitle">CityXerpa</h4>
           <p class="paragraph-sm" translate>infoXerpa</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/smartlink?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/smartlink/smartlink_portada.jpg" alt="">
         <div class="box-text-portfolio proj-smartlink">
           <h4 class="line-subtitle">Smartlink</h4>
           <p class="paragraph-sm" translate>infoSmartlink</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
      <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/beabloo-halo?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/beabloo-halo/halo_portada.jpg" alt="">
         <div class="box-text-portfolio proj-beabloo-halo">
           <h4 class="line-subtitle">{{'virtual_assistant' | translate}}</h4>
           <p class="paragraph-sm" translate>infoVirtualAssistant</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/makeyoursuit/makeyoursuit_portada.jpg" alt="">
         <div class="box-text-portfolio proj-makeyoursuit">
           <h4 class="line-subtitle">Makeyoursuit</h4>
           <p class="paragraph-sm" translate>infoMakeyoursuit</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
      <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/schweppes?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/schweppes/schweppes_portada.jpg" alt="">
         <div class="box-text-portfolio proj-schweppes">
           <h4 class="line-subtitle">Schweppes</h4>
           <p class="paragraph-sm" translate>infoSchweppes</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/tedclothing?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/tedclothing/tedclothing_portada.jpg" alt="">
         <div class="box-text-portfolio proj-tedclothing">
           <h4 class="line-subtitle">Ted Clothing</h4>
           <p class="paragraph-sm" translate>info_tedclothing</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/nestle?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/nestle/nestle_portada.jpg" alt="">
         <div class="box-text-portfolio proj-nestle">
           <h4 class="line-subtitle">Nestle</h4>
           <p class="paragraph-sm" translate>info_nestle</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     <div class="box-3">
       <a class="box-img-portfolio" [attr.href]="'#/portfolio/saltinbanquin?lang=' + this.translate.currentLang" translate>
         <img src="/assets/images/portfolio/saltinbanquin/saltinbanquin_portada.jpg" alt="">
         <div class="box-text-portfolio proj-saltinbanquin">
           <h4 class="line-subtitle">Saltinbanquin</h4>
           <p class="paragraph-sm" translate>info_saltin_banquin</p>
            <span class="bottom-fixed">{{'view_project' | translate}}<i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
         </div>
       </a>
     </div>
     

   </div>

   <div id="carousel-example-generic" class="carousel slide hidden-md-up" data-ride="carousel">

     <!-- Wrapper for slides -->
     <div class="carousel-inner" role="listbox">
       <div class="carousel-item">
            <a [attr.href]="'#/portfolio/beabloo-website?lang=' + this.translate.currentLang" translate><img src="assets/images/portfolio/beabloo-website/beabloo-website_portada.jpg" alt="imagen del proyecto"></a>
          </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/backend?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/backend/backend_portada.jpg" alt="imagen del proyecto"></a>
       </div>
        <div class="carousel-item active">
         <a [attr.href]="'#/portfolio/xerpa?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/xerpa/xerpa_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/smartlink?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/smartlink/smartlink_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/makeyoursuit/makeyoursuit_portada.jpg" alt="imagen del proyecto"></a>
       </div>
        <div class="carousel-item">
         <a [attr.href]="'#/portfolio/schweppes?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/schweppes/schweppes_portada.jpg" alt="imagen del proyecto"></a>
       </div>

       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/procter?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/procter/procter_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/tedclothing?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/tedclothing/tedclothing_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/saltinbanquin?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/saltinbanquin/saltinbanquin_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/nestle?lang=' + this.translate.currentLang" ><img src="/assets/images/portfolio/nestle/nestle_portada.jpg" alt="imagen del proyecto"></a>
       </div>
       <div class="carousel-item">
         <a [attr.href]="'#/portfolio/impremta?lang=' + this.translate.currentLang" translate><img src="/assets/images/portfolio/impremta/impremta_portada.jpg" alt="imagen del proyecto"></a>
       </div>
     </div>

     <!-- Controls -->
     <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
       <span class="icon-prev" aria-hidden="true"></span>
       <span class="sr-only" translate>Previous</span>
     </a>
     <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
       <span class="icon-next" aria-hidden="true"></span>
       <span class="sr-only" translate>Next</span>
     </a>
   </div>
   <a class="btn btn-primary btn-center" href="../index.html"><i class="fa fa-long-arrow-left margin-xs-r" aria-hidden="true"></i>{{'return' | translate}}</a>


 </div>
</article>
<!-- end portfolio -->
