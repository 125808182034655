<section class="proj-beabloo-halo box-portfolio-project">
  <article class="box-title-proj">
      <h1>{{'virtual_assistant_store'| translate}}<span class="paragraph-md"> 2019</span></h1>
       <ul>
        <li><a (click)="scroll('company')">{{'project_info'| translate}}</a></li>
        <li><a (click)="scroll('user_research')">{{'user_research'| translate}}</a></li>
        <li><a (click)="scroll('prototype')">{{'prototype'| translate}}</a></li>
        <li><a (click)="scroll('design')">{{'design_user_test'| translate}}</a></li>
      </ul>
    </article>
   <!-- start about company -->
   <a id="company"></a>
    <article class=" background-portfolio-beabloo-halo">
      <div class="container">
        <h3>{{'virtual_assistant'| translate}}</h3>
        <p class="max-text-sm"><strong>{{'virtual_assistant_1'| translate}}</strong></p>
        <p class="max-text-sm">{{'virtual_1assistant_2'| translate}}</p>
      </div>
    </article>
    <img class="hide-sm-up" src="assets/images/portfolio/beabloo-halo/background_halo_mobile.jpg" alt="">
    <!-- end about company -->
    <article class="cf">
      <div class="container">
        <div class="box-2">
          <h3>{{'my_role' | translate }}</h3>
          <p>{{'va_role_1'| translate}}
        	</p>    
        </div>
        <div class="box-2">
          <h3>{{'planing_scope' | translate }}</h3>
          <p>{{'va_planning_scope_1'| translate}}</p>
        </div>
      </div>
    </article>
    <article class="cf">
      <div class="container">
        <div class="cf box-2">
          <h3>{{'my_challenge' | translate }}</h3>
          <p>{{'va_challenge_1'| translate}}</p>    
        </div>
        <div class="cf box-2">
          <h3>{{'results' | translate }}</h3>
          <p>{{'va_results_1'| translate}}</p>    
        </div>
      </div>
    </article>

  <!-- start about company -->
   <a id="user_research"></a>
    <article class="cf">
      <div class="container cf">
        <div class="cf box-2">
          <h3>User research - Asistentes virtuales</h3>
          <p>{{'va_user_research_1'| translate}}</p>
          <h6>Stakeholders brainstorming</h6>
          <p>{{'va_stakeholders_1'| translate}}</p>
          <h6>{{'va_clients_partners'| translate}}</h6>
          <p>{{'va_interview_partner'| translate}}</p>
          <h6>{{'va_interview-worker'| translate}}</h6>
          <p>{{'va_interview_worker_1'| translate}}</p>        
        </div>
        <div class="cf box-2">
          <img src="assets/images/portfolio/beabloo-halo/brainstorming.jpg" alt="Brainstorming session">
          <h6 class="max-text margin-md-t">{{'va_insights'| translate}}</h6>
          <p class="max-text"><span class="point-text">1. </span>{{'va_insights1'| translate}}</p>
          <p class="max-text"><span class="point-text">2. </span>{{'va_insights2'| translate}}</p>
          <p class="max-text"><span class="point-text">3. </span>{{'va_insights3'| translate}}</p>  
        </div>
      </div>
      <div class="container margin-md-t">
        <p class="highlight-text"><strong>USER CASE: </strong>{{'va_user_case'| translate}}</p>
      </div>
    </article>
  <!-- end -->

 <a id="prototype"></a>
    <article>
      <div class="container container-sm cf">
          <h3>{{'va_prototipe'| translate}}</h3>
          <p class="text-max-width">{{'va_prototipe1'| translate}}</p>
          <img class="img-md border" src="assets/images/portfolio/beabloo-halo/user_flow2.jpg" alt="User flow">
          <p class="footer-img-left">{{'va_user_flow'| translate}}</p>
      </div>
  <a id="design"></a>
      <div class="container margin-md-t container-sm cf">
        <h3>{{'va_design'| translate}}</h3>
        <p class="">{{'va_design_text'| translate}}</p>
        <p><span class="point-text">1. </span>{{'va_design1'| translate}}</p>
        <p><span class="point-text">2. </span>{{'va_design2'| translate}}</p>
        <p><span class="point-text">3. </span>{{'va_design3'| translate}}</p>
        <img class="img-md" src="assets/images/portfolio/beabloo-halo/virtual_assistant_test.png" alt="User Test virtual assistant">
        <p class="footer-img-left">User Test</p>
      </div>
    </article>

    <!-- start other projects -->
      <div class="container container-links cf margin-md-b">
          <a class="pull-left btn btn-primary" [attr.href]="'#/portfolio?lang=' + this.translate.currentLang" translate>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            {{'return_portfolio' | translate }}
          </a>
          <a class="pull-right btn btn-primary" [attr.href]="'#/portfolio/makeyoursuit?lang=' + this.translate.currentLang" translate>
            {{'view_project' | translate }}: Makeyoursuit
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </a>
      </div>
    <!-- end other projects -->
    </section>

